.socialmediaicon .socialmediaiconlists .mediaiconstag {
  text-align: center;
  display: inline-block;
  position: relative;
  overflow: hidden;
  /* border: 3px solid var(--white); */
  z-index: 1;
}

.socialmediaicon .socialmediaiconlists .mediaiconstag .icon {
  position: relative;
  color: var(--white);
  background-color: var(--theme-color);
  border-right: 2px solid #e4e4e4;
  transition: 1.5s;
  z-index: 3;
}

/* Remove border-right for the 5th element with the special class */
.socialmediaicon .socialmediaiconlists .mediaiconstag .icon.special {
  border-right: none;
}

.socialmediaicon .socialmediaiconlists .mediaiconstag:hover .icon {
  color: #fff;
  background-color: transparent;
  /* transform: rotateY(380deg); */
}

.socialmediaicon .socialmediaiconlists .mediaiconstag:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  /* transition: 0.5s; */
  z-index: 2;
}

.socialmediaicon .socialmediaiconlists .mediaiconstag:hover:before {
  top: 0;
}

.socialmediaicon .socialmediaiconlists:nth-child(1) .mediaiconstag:before {
  background: #3b5999;
}

.socialmediaicon .socialmediaiconlists:nth-child(2) .mediaiconstag:before {
  background: black;
}

.socialmediaicon .socialmediaiconlists:nth-child(3) .mediaiconstag:before {
  background: #25d366;
}

.socialmediaicon .socialmediaiconlists:nth-child(4) .mediaiconstag:before {
  background: #cd201f;
}
.socialmediaicon .socialmediaiconlists:nth-child(5) .mediaiconstag:before {
  background: #ff0050;
}
